import {WEEKDAY} from '@wix/wix-events-commons-statics'
import {withTranslation, WithTranslation} from 'react-i18next'
import {CalendarMonth} from '../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../utils/calendar'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MonthlyCalendarGrid as CalendarGridPresentation} from './monthly-calendar-grid'

export interface MonthlyCalendarGridOwnProps {}

export interface MonthlyCalendarGridRuntimeProps {
  calendarWeeks: CalendarMonth
  weekdayNames: WEEKDAY[]
  borderStyle: React.CSSProperties
}

export interface MonthlyCalendarGridProps
  extends MonthlyCalendarGridRuntimeProps,
    MonthlyCalendarGridOwnProps,
    WithTranslation {}

export interface MonthlyCalendarCellRefs {
  [week: number]: MonthlyCalendarWeekCellRefs
}

export interface MonthlyCalendarWeekCellRefs {
  [weekDay: number]: HTMLElement
}

export interface MonthlyCalendarCellSizes {
  [week: number]: MonthlyCalendarWeekCellSizes
}

export interface MonthlyCalendarWeekCellSizes {
  [weekDay: number]: MonthlyCalendarCellSize
}

export interface MonthlyCalendarCellSize {
  width: number
  height: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarGridRuntimeProps => {
  return {
    calendarWeeks: context.state.calendarLayout.weeks,
    weekdayNames: context.state.calendarLayout.weekDayNames,
    borderStyle: getBorderStyle(context),
  }
}

export const MonthlyCalendarGrid = connect<MonthlyCalendarGridOwnProps, MonthlyCalendarGridRuntimeProps>(mapRuntime)(
  withTranslation()(CalendarGridPresentation),
)
