import React from 'react'
import {Members} from '../../members'
import s from './members.scss'
import {SingleEventMembersProps} from './index'

export const SingleEventMembers: React.FC<SingleEventMembersProps> = ({event, onImage}: SingleEventMembersProps) => (
  <div className={s.members} data-hook="ev-members">
    <Members event={event} singleLayout={true} onImage={onImage} />
  </div>
)
