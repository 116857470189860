import {isMobile} from '../../../commons/selectors/environment'
import {
  getCardMargins,
  getListGeneralTitle,
  getListStripWidth,
  isCardsLayout,
  isListLayout,
} from '../../../commons/selectors/settings'
import {isMainTitleVisible} from '../../selectors/list-settings'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Title as TitlePresentation} from './title'

interface TitleStateProps {
  title: string
  listLayout: boolean
  listStripWidth: number
  mobile: boolean
  visible: boolean
  cardsLayout: boolean
  cardMargins: number
}

export interface TitleProps extends TitleStateProps {}

const mapRuntimeToContext = ({state}: AppProps): TitleStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    title: getListGeneralTitle(componentConfig),
    listLayout: isListLayout(componentConfig),
    cardsLayout: isCardsLayout(componentConfig),
    listStripWidth: getListStripWidth(componentConfig),
    mobile: isMobile(state),
    visible: isMainTitleVisible(state),
    cardMargins: getCardMargins(componentConfig),
  }
}

export const Title = connect<{}, TitleStateProps>(mapRuntimeToContext)(TitlePresentation)
