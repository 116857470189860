import {TFunction} from 'i18next'
import {MonthlyCalendarCellSizes} from '..'
import {isEditor} from '../../../../../../commons/selectors/environment'
import {closeMonthlyCalendarPopup} from '../../../../../actions/calendar-layout'
import {getEventDetailsStyleHash} from '../../../../../selectors/calendar-layout'
import {getBorderWidth} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MonthlyCalendarPopup as MonthlyCalendarPopupPresentation} from './monthly-calendar-popup'

export interface MonthlyCalendarPopupOwnProps {
  getCellSizes: () => MonthlyCalendarCellSizes
  t: TFunction
}

export interface MonthlyCalendarPopupRuntimeProps {
  open: boolean
  week: number
  weekDayIndex: number
  selectedEventId: string
  borderWidth: number
  popupStyleHash: string
  isEditor: boolean
  rtl: boolean
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface MonthlyCalendarPopupProps extends MonthlyCalendarPopupRuntimeProps, MonthlyCalendarPopupOwnProps {}

export interface MonthlyCalendarState {
  contentHeight: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarPopupRuntimeProps => {
  const {
    monthly: {selectedDate},
  } = context.state.calendarLayout

  const insideEditor = isEditor(context.state)
  return {
    ...selectedDate,
    open: Boolean(selectedDate),
    selectedEventId: context.state.calendarLayout.monthly.selectedEventId,
    borderWidth: getBorderWidth(context),
    popupStyleHash: insideEditor && getEventDetailsStyleHash(context),
    isEditor: insideEditor,
    rtl: context.isRTL,
    closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
  }
}

export const MonthlyCalendarPopup = connect<MonthlyCalendarPopupOwnProps, MonthlyCalendarPopupRuntimeProps>(mapRuntime)(
  MonthlyCalendarPopupPresentation,
)
