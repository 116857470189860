import {isMobile} from '../../../../commons/selectors/environment'
import {
  getCardBorderWidth,
  getListDividerWidth,
  getListStripWidth,
  isListLayout,
} from '../../../../commons/selectors/settings'
import {isItemOpened} from '../../../selectors/layout'
import {isAdditionalComponentsHidden, isNarrow} from '../../../selectors/list-settings'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {ItemContainerOwnProps, ItemContainerStateProps} from './interfaces'
import {ItemContainer as ItemContainerPresentation} from './item'

const mapRuntime = ({state, actions, host: {dimensions}}: AppProps, ownProps: ItemContainerOwnProps) => {
  const componentConfig = getComponentConfig(state)
  const listLayout = isListLayout(componentConfig)
  const mobile = isMobile(state)
  const listStripWidth = getListStripWidth(componentConfig, mobile)

  return {
    listDividerWidth:
      mobile && !listLayout ? getCardBorderWidth(componentConfig) : getListDividerWidth(componentConfig),
    listStripWidth,
    additionalComponentsHidden: isAdditionalComponentsHidden(state, ownProps.event.id),
    opened: isItemOpened(state, ownProps.event.id),
    mobile: isNarrow(state, dimensions) || isMobile(state),
    listLayout,
    close: actions.closeListLayoutItems,
    open: actions.openListLayoutItem,
  }
}

export const ItemContainer = connect<ItemContainerOwnProps, ItemContainerStateProps>(mapRuntime)(
  ItemContainerPresentation,
)
export * from './interfaces'
