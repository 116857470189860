import {TFunction} from 'i18next'
import {closeMonthlyCalendarPopup} from '../../../../../../actions/calendar-layout'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventList as MonthlyCalendarEventListPresentation} from './monthly-calendar-event-list'

export interface MonthlyCalendarEventListOwnProps {
  t: TFunction
}

export interface MonthlyCalendarEventListRuntimeProps {
  dateText: string
  events: ExtendedEvent[]
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: (eventId: string) => void
}

export interface MonthlyCalendarEventListProps
  extends MonthlyCalendarEventListRuntimeProps,
    MonthlyCalendarEventListOwnProps {}

const mapRuntime = (context: AppProps): MonthlyCalendarEventListRuntimeProps => {
  const {week, weekDayIndex} = context.state.calendarLayout.monthly.selectedDate
  return {
    events: context.state.calendarLayout.weeks[week][weekDayIndex].events,
    dateText: context.state.calendarLayout.weeks[week][weekDayIndex].dayMonthText,
    openMonthlyCalendarEvent: (eventId: string) => context.actions.openMonthlyCalendarEvent(eventId),
    closeMonthlyCalendarPopup: () => context.actions.closeMonthlyCalendarPopup(),
  }
}

export const MonthlyCalendarEventList = connect<MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps>(
  mapRuntime,
)(MonthlyCalendarEventListPresentation)
