import classNames from 'classnames'
import React from 'react'
import {DH} from '../../../utils/data-hooks'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({event, onImage, isMobile}: TitleProps) => (
  <div
    className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
      [s.onImage]: onImage,
      [s.mobile]: isMobile,
    })}
    data-hook={DH.eventTitle}
  >
    <LinkToPage event={event}>{event.title}</LinkToPage>
  </div>
)
