import {isWithoutRegistration, isRegistrationExternal, prependHTTP} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../commons/selectors/environment'
import {isEventDetailsLinkEnabled} from '../../../commons/selectors/experiments'
import {getListButtonStyle} from '../../../commons/selectors/settings'
import {getRSVPButtonTextForEventListItem, hasEventDetailsUrl} from '../../selectors/events'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'
import {ButtonOwnProps, ButtonStateProps} from './interfaces'

const mapRuntime = (
  {state, actions, baseUrl, pageUrl, queryParams}: AppProps,
  {event}: ButtonOwnProps,
): ButtonStateProps => {
  const navigateToPage = () => {
    if (isRegistrationExternal(event)) {
      window.open(prependHTTP(event.registration.external.registration), '_blank')
    }
    actions.navigateToPage(event)
  }

  return {
    navigateToPage: isEventDetailsLinkEnabled(state.experiments) ? () => actions.navigateToPage(event) : navigateToPage,
    mobile: isMobile(state),
    text: getRSVPButtonTextForEventListItem(state, event),
    style: getListButtonStyle(getComponentConfig(state)),
    withoutRegistration: isWithoutRegistration(event),
    hasUrl: hasEventDetailsUrl(state, event),
  }
}

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)

export * from './interfaces'
