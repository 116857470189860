import {
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
} from '../../../../../actions/calendar-layout'
import {CalendarDay} from '../../../../../selectors/calendar-layout'
import {getActionBackground, getBorderStyle, getOtherMonthBackground} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {CalendarCell as CalendarCellPresentation} from './calendar-cell'

export interface CalendarCellOwnProps {
  day: CalendarDay
  week: number
  weekDay: number
  registerContainerRef: (week: number, weekDay: number, ref: HTMLElement) => void
}

export interface CalendarCellState {
  hover: boolean
}

export interface CalendarCellRuntimeProps {
  selectionExists: boolean
  active: boolean
  borderStyle: React.CSSProperties
  otherMonthBackground: React.CSSProperties
  actionBackground: React.CSSProperties
  openMonthlyCalendarPopup: typeof openMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface CalendarCellProps extends CalendarCellRuntimeProps, CalendarCellOwnProps {}

const mapRuntime = (context: AppProps, ownProps: CalendarCellOwnProps): CalendarCellRuntimeProps => ({
  selectionExists: Boolean(context.state.calendarLayout.monthly.selectedDate),
  active: ownProps.day.timestamp === context.state.calendarLayout.monthly?.selectedDate?.timestamp,
  borderStyle: getBorderStyle(context),
  otherMonthBackground: getOtherMonthBackground(context),
  actionBackground: getActionBackground(context),
  openMonthlyCalendarPopup: context.actions.openMonthlyCalendarPopup,
  openMonthlyCalendarEvent: context.actions.openMonthlyCalendarEvent,
  closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
})

export const CalendarCell = connect<CalendarCellOwnProps, CalendarCellRuntimeProps>(mapRuntime)(
  CalendarCellPresentation,
)
